
@import "../../assets/scss/cmsCommon_image_index.scss";
.detail-index-crumbs-m {
    display: flex;
    align-items: center;
    font-size: 0.14rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 0.2rem;
    margin-bottom: 0.28rem;
    width: 12rem;
    margin: auto;
    padding-top: 0.3rem;
}
.detail-index-crumbs-up {
    color: #273143;
    margin-right: 0.1rem;
}

.detail-index-crumbs-this {
    color: #761f1e;
    margin-left: 0.1rem;
}
.partyBuilding-container-bread{
    padding-top: 0.3rem;
}
.tab-secondNav-choose-item{
  font-size: 0.17rem;
  border-bottom: 1px solid #D5D5D5;
  padding-bottom: 0.14rem;
}
.tab-secondNav-choose-item{
  display: flex;
}
.tab-secondNav-choose-item li{
  margin-left: 10px;
}
.tab-secondNav-choose-item li{
  cursor: pointer;
}
.tab-secondNav-choose-item li:not(:first-child){
  cursor: pointer;
  margin-left: 0.46rem;
}
.tabActivesecondNav{
  color: #761F1E;
  position: relative;
}

.tabActivesecondNavSpan{
  width: 100%;
  height: 3px;
  display: inline-block;
  position: absolute;
  bottom: -0.14rem;
  left: 0rem;
}
.tabActivesecondNavSubSpan{
  width: 80%;
  background: #991C10;
  height: 3px;
  display: inline-block;
  position: absolute;
  animation: 0px;
  margin: auto;
  left: 0px;
  right: 0px;
}
.tab-subSwitch{
  margin-top: 0.33rem;
}
.news-container{
  padding-top: 0.38rem;
}
