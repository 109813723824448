// 最外层盒子样式
%out-box {
  //   width: (1920rem / 100);
  position: relative;
}

// 1920设计稿，所有的值除以30；

// 内层盒子
%inside-box {
  width: (1200rem / 100);
  margin: 0 auto;
}

.artistic-container {
  @extend %out-box;
  background: url("https://image.bookgo.com.cn/%20webculture/jm/bg/bg_body.jpg");
  padding-bottom: 1.6rem;
  .artistic-content {
    @extend %inside-box;
    position: relative;
    padding-top: (38rem / 100);
    .artistic-switch-type-pic{
      width: 6.6rem;
        height: 1.85rem;
        position: absolute;
        top: 0rem;
        right: 0;
        // z-index: -1;
        img {
          width: 100%;
          height: 100%;
        }
    }
    // 顶部
    .artistic-switch-type {
      display: flex;
      margin-bottom: (12rem / 100);
      position: relative;
      z-index: 1;
      margin-top: 0.3rem;
      &-title {
        font-size: (16rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #761f1e;
        line-height: (32rem / 100);
        margin-right: (10rem / 100);
        white-space: nowrap;
      }
      // &-pic {
      //   width: 6.6rem;
      //   height: 1.85rem;
      //   position: absolute;
      //   top: -0.78rem;
      //   right: 0;
      //   z-index: -1;
      //   img {
      //     width: 100%;
      //     height: 100%;
      //   }
      // }

      &-content {
        display: flex;
        flex-wrap: wrap;

        &-li {
          min-width: (100rem / 100);
          height: (32rem / 100);
          margin: 0 (30rem / 100) (12rem / 100) 0;
          background: url("https://image.bookgo.com.cn/webculture/jm/normal_type_li2.png")
            no-repeat;
          background-size: 100% 100%;
          text-align: center;
          line-height: (32rem / 100);
          font-size: (16rem / 100);
          font-family: PingFangSC-Regular, PingFang SC;
          color: #273143;
          cursor: pointer;
          padding-left: 0.2rem;
          padding-right: 0.2rem;
        }
        .imgType{
          background: url("https://image.bookgo.com.cn/webculture/jm/normal_type_li.png")
          no-repeat;
          background-size: 100% 100%;
        }
        &-li-active {
          background: url("https://image.bookgo.com.cn/webculture/jm/activity_type_li.png")
            no-repeat !important;
          background-size: 100% 100% !important;
          color: #ffffff;
          font-weight: 500;
        }
      }
    }

    .artistic-count-info {
      font-size: (14rem / 100);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #273143;
      line-height: (20rem / 100);
      margin-bottom: (28rem / 100);
      margin-top: (20rem / 100);
    }

    // 列表
    .artistic-switch-content {
      &-li {
        width: 100%;
        height: (260rem / 100);
        padding: (30rem / 100);
        border: 4px solid transparent;
        position: relative;
        display: flex;
        align-items: center;
        background: #ffffff;
        margin-bottom: (20rem / 100);
        cursor: pointer;
        a {
          display: flex;
          align-items: center;
        }

        .interact-index-container-FAQ-content-headPic {
          position: absolute;
          top: -4px;
          left: -4px;
          width: (1200rem / 100);
          height: (33rem / 100);
        }

        .interact-index-container-FAQ-content-footerPic {
          position: absolute;
          bottom: -4px;
          left: -4px;
          width: (1200rem / 100);
          height: (33rem / 100);
        }

        .interact-index-container-FAQ-content-headPic-active {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: (33rem / 100);
          display: none;
        }

        .interact-index-container-FAQ-content-footerPic-active {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: (33rem / 100);
          display: none;
        }

        &-left {
          width: (340rem / 100);
          height: (200rem / 100);
          margin-right: (20rem / 100);
        }

        &-right {
          width: (780rem / 100);
          height: (200rem / 100);
          position: relative;

          &-title {
            width: 100%;
            height: (28rem / 100);
            font-size: (18rem / 100);
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #273143;
            line-height: (28rem / 100);
            margin-bottom: (24rem / 100);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          &-des {
            width: 100%;
            height: (44rem / 100);
            font-size: (14rem / 100);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #273143;
            line-height: (22rem / 100);
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }

          &-time {
            position: absolute;
            right: 0;
            bottom: 0;
            font-size: (14rem / 100);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #56657f;
            line-height: (14rem / 100);
          }
        }
      }

      .artistic-switch-content-li:hover {
        border: 4px solid;
        border-color: #cd9e56;

        .interact-index-container-FAQ-content-headPic {
          display: none;
        }

        .interact-index-container-FAQ-content-footerPic {
          display: none;
        }

        .interact-index-container-FAQ-content-headPic-active {
          display: block;
        }

        .interact-index-container-FAQ-content-footerPic-active {
          display: block;
        }
      }
    }
  }
}
